<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Vytvoriť projekt

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie projektu je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="ProjectForm.name"
              :error-messages="errors.name"
              :outlined="true"
              :dense="true"
              label="Meno"
          />

          <SuggestClients
              v-model="ProjectForm.Client"
              :error-messages="errors.Client"
              :outlined="true"
              :dense="true"
              label="Klient"
              :clearable="true"
              :createButton="true"
          />

          <v-text-field
              v-model="ProjectForm.type"
              :error-messages="errors.type"
              :outlined="true"
              :dense="true"
              label="Typ"
          />

          <v-text-field
              v-model="ProjectForm.discount"
              :error-messages="errors.discount"
              :outlined="true"
              :dense="true"
              label="Zľava (%)"
          />

          <EditorPartials
              v-model="ProjectForm.noteProduction"
              label="Prístupy - Produkčné"
          />

          <EditorPartials
              v-model="ProjectForm.noteDevelopment"
              label="Prístupy - Vývojové"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Projects from '@/services/projects'

  import SuggestClients from '@/components/Clients/Suggest'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Create',
    components: {
      SuggestClients,
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Project: {},
        ProjectForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.ProjectForm = {
          name: '',
          Client: null,
          type: '',
          discount: 0,
          noteDevelopment: '',
          noteProduction: ''
        }

        this.errors = {}
      },
      submit() {
        this.loading = true

        Projects
          .create({
            data: this.ProjectForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
