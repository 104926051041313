import Vue from 'vue'

import VueRouter from 'vue-router'

import Session from '@/middleware/session'

import Home from '@/views/Home'
import Error from '@/views/Error'
import TicketItemAuth from '@/views/TicketItemAuth'
import Accounts from '@/views/Accounts'
import Tariffs from '@/views/Tariffs'
import Users from '@/views/Users'
import Clients from '@/views/Clients'
import Projects from '@/views/Projects'
import TicketsAll from '@/views/TicketsAll'
import TicketsNew from '@/views/TicketsNew'
import TicketsInWaiting from '@/views/TicketsInWaiting'
import TicketsInProgress from '@/views/TicketsInProgress'
import TicketsRejected from '@/views/TicketsRejected'
import TicketsClosed from '@/views/TicketsClosed'
import Ticket from '@/views/Ticket'
import TicketItem from '@/views/TicketItem'
import Statistics from '@/views/Statistics'
import ReportStatistics from '@/views/ReportStatistics'
import Revenues from '@/views/Revenues'
import Expenses from '@/views/Expenses'
import Bonuses from '@/views/Bonuses'
import Provisions from '@/views/Provisions'
import TicketItemReports from '@/views/TicketItemReports'
import TicketItems from '@/views/TicketItems'
import UserStatistics from '@/views/UserStatistics'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '*',
    redirect: '/error'
  },
  {
    path: '/home',
    component: Home,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/error',
    component: Error,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/ticket-item-auth/:id',
    component: TicketItemAuth,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER',
            'ROLE_WORKER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/accounts',
    component: Accounts,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/tariffs',
    component: Tariffs,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/users',
    component: Users,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/clients',
    component: Clients,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/projects',
    component: Projects,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/tickets/all',
    component: TicketsAll,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/tickets/new',
    component: TicketsNew,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/tickets/in-waiting',
    component: TicketsInWaiting,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/tickets/in-progress',
    component: TicketsInProgress,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/tickets/rejected',
    component: TicketsRejected,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/tickets/closed',
    component: TicketsClosed,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/ticket/:id',
    component: Ticket,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/ticket-item/:id',
    component: TicketItem,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER',
            'ROLE_EDITOR',
            'ROLE_MANAGER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/statistics',
    component: Statistics,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/report-statistics',
    component: ReportStatistics,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/revenues',
    component: Revenues,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/expenses',
    component: Expenses,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/bonuses',
    component: Bonuses,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/provisions',
    component: Provisions,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/ticket-item-reports',
    component: TicketItemReports,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/ticket-items',
    component: TicketItems,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/user-statistics',
    component: UserStatistics,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
