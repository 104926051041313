import Vue from 'vue'

class UserProfile {
  async exist() {
    return await Vue.axios
      .get('admin/user-profile/exist')
  }

  async set({data}) {
    return await Vue.axios
      .put('admin/user-profile/set', data)
  }

  async setPassword({data}) {
    return await Vue.axios
      .put('admin/user-profile/set/password', data)
  }
}

const userProfile = new UserProfile()

export default userProfile
