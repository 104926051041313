import Vue from 'vue'

class Cron {
  async ticketsSynchronize() {
    return await Vue.axios
      .get('web/cron/tickets/synchronize')
  }
}

const cron = new Cron()

export default cron
