<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Vytvoriť tarifu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre vytvorenie tarify je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="TariffForm.title"
              :error-messages="errors.title"
              :outlined="true"
              :dense="true"
              label="Názov"
          />

          <v-textarea
              v-model="TariffForm.description"
              :error-messages="errors.description"
              :outlined="true"
              :dense="true"
              label="Popis"
              :rows="2"
          />

          <v-text-field
              v-model="TariffForm.price"
              :error-messages="errors.price"
              :outlined="true"
              :dense="true"
              label="Sadzba (€)"
          />

          <v-select
              v-model="TariffForm.unit"
              :error-messages="errors.unit"
              :outlined="true"
              :dense="true"
              label="Jednotka"
              :items="$store.getters['register/getTariffUnit']"
          />

          <v-select
              v-model="TariffForm.frequency"
              :error-messages="errors.frequency"
              :outlined="true"
              :dense="true"
              label="Frekvencia"
              :items="$store.getters['register/getTariffFrequency']"
          />

          <v-select
              v-model="TariffForm.type"
              :error-messages="errors.type"
              :outlined="true"
              :dense="true"
              label="Typ"
              :items="$store.getters['register/getTariffType']"
          />

          <template
              v-if="TariffForm.type === $store.state.register.Tariff.type['group'].value"
          >
            <v-text-field
                v-model="TariffForm.timeOffers"
                :error-messages="errors.timeOffers"
                :outlined="true"
                :dense="true"
                label="Čas práce (h)"
            />

            <v-text-field
                v-model="TariffForm.priceOffers"
                :error-messages="errors.priceOffers"
                :outlined="true"
                :dense="true"
                label="Cena práce (€)"
            />
          </template>

          <template
              v-if="TariffForm.type === $store.state.register.Tariff.type['simple'].value"
          >
            <v-text-field
                v-model="TariffForm.minProfit"
                :error-messages="errors.minProfit"
                :outlined="true"
                :dense="true"
                label="Minimálny zárobok (%)"
            />
          </template>

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="success"
              @click="submit"
          >
            <v-icon>
              mdi-plus
            </v-icon>

            Vytvoriť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Tariffs from '@/services/tariffs'

  export default {
    name: 'Create',
    data() {
      return {
        loading: false,
        dialog: false,
        TariffForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.TariffForm = {
          title: '',
          description: '',
          price: 0,
          unit: this.$store.state.register.Tariff.unit['h.'].value,
          frequency: this.$store.state.register.Tariff.frequency['once'].value,
          type: this.$store.state.register.Tariff.type['simple'].value,
          timeOffers: 0,
          priceOffers: 0,
          minProfit: 40
        }

        this.errors = {}
      },
      submit() {
        this.loading = true

        Tariffs
          .create({
            data: this.TariffForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
