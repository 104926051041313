<template>
  <v-btn
      :icon="true"
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Upraviť profil

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie profilu je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="UserForm.name"
              :error-messages="errors.name"
              :outlined="true"
              :dense="true"
              label="Meno"
          />

          <v-text-field
              v-model="UserForm.emailBusiness"
              :error-messages="errors.emailBusiness"
              :outlined="true"
              :dense="true"
              label="E-mail firemný"
          />

          <v-text-field
              v-model="UserForm.emailPersonal"
              :error-messages="errors.emailPersonal"
              :outlined="true"
              :dense="true"
              label="E-mail osobný"
          />

          <v-text-field
              v-model="UserForm.phone"
              :error-messages="errors.phone"
              :outlined="true"
              :dense="true"
              label="Telefón"
              :persistent-hint="true"
              hint="V prípade, že nevieš tak: xxxxxxxxxxxx"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import UserProfile from '@/services/user-profile'

  export default {
    name: 'ProfileSet',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        User: {},
        UserForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        UserProfile
          .exist()
          .then((response) => {
            this.dialog = true

            this.User = response.data.data.User

            this.UserForm = {
              name: response.data.data.User.name,
              emailBusiness: response.data.data.User.emailBusiness,
              emailPersonal: response.data.data.User.emailPersonal,
              phone: response.data.data.User.phone
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      submit() {
        this.loading = true

        UserProfile
          .set({
            data: this.UserForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            window.location.reload()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
