<template>
  <v-card>
    <v-card-title>
      Plány

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <v-slide-group
          v-if="users.length > 0"
      >
        <v-slide-item
            v-for="(User) in sortedUsersMe"
            :key="User.id"
        >
          <User
              :User="User"
              @init="init"
          />
        </v-slide-item>

        <v-slide-item
            v-for="(User) in sortedUsersNotMe"
            :key="User.id"
        >
          <User
              :User="User"
              @init="init"
          />
        </v-slide-item>
      </v-slide-group>

      <v-alert
          v-else
          type="error"
      >
        Nenašli sa žiadne plány.
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
  import Users from '@/services/users'

  import User from '@/components/Home/Users/User'

  export default {
    name: 'Users',
    components: {
      User
    },
    data() {
      return {
        loading: false,
        users: []
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      sortedUsersMe() {
        const sortedUsersMe = [...this.users]

        sortedUsersMe.sort((a, b) => new Date(b.name) - new Date(a.name))

        return sortedUsersMe.filter((User) => User.id === this.$store.state.auth.User.id)
      },
      sortedUsersNotMe() {
        const sortedUsersNotMe = [...this.users]

        sortedUsersNotMe.sort((a, b) => new Date(b.name) - new Date(a.name))

        return sortedUsersNotMe.filter((User) => User.id !== this.$store.state.auth.User.id)
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        this.loading = true

        Users
          .all()
          .then((response) => {
            this.users = response.data.data.users
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
